import React, { useState } from 'react';

import classes from '../../../../assets/Global.module.css';
import Button from '../../../../assets/essentials/Button';
import Input from '../../../../assets/essentials/Input';
import Select from '../../../../assets/essentials/Select';
import TextArea from '../../../../assets/essentials/TextArea';
import PutForm from '../../../../api/internal/PutForm';
import OptionList from '../../../../helpers/options/OptionList';
import Form from '../../../../assets/essentials/Form';
import Phone from '../../../../helpers/inputs/Phone';

function Details({
  data,
  employeeId,
  RefreshPage,
}: {
  data: DriverDetails;
  employeeId: number;
  RefreshPage: Function;
}) {
  const [htmlPhone, setHtmlPhone] = useState(data?.dispatchPhone);
  const [dispatchOptions, setDispatchOptions] = useState(
    GetDefaultDispatchOptions(),
  );

  function GetDefaultDispatchOptions() {
    let branchIndex = data.branchOptions.findIndex(
      x => x.branchId == data.branchId,
    );
    return (
      <div className={classes.attribute}>
        <label htmlFor="preferredDispatchId">Dispatch</label>
        <Select
          name="preferredDispatchId"
          id="preferredDispatchId"
          defaultValue={
            data.preferredDispatchId ? data.preferredDispatchId : ''
          }
          onChange={e => UpdateDispatchPhone(Number(e.target.value))}
        >
          <option value="" />
          <OptionList
            optionData={data.branchOptions[branchIndex]?.dispatchOptions}
            attributeID="dispatchId"
            attributeName="dispatchName"
          />
        </Select>
      </div>
    );
  }

  function UpdateBranch(branchId: number) {
    let branchIndex = data.branchOptions.findIndex(x => x.branchId == branchId);
    setHtmlPhone('');
    setDispatchOptions(
      <div className={classes.attribute} key={branchId}>
        <label htmlFor="preferredDispatchId">Dispatch</label>
        <Select
          name="preferredDispatchId"
          id="preferredDispatchId"
          onChange={e => UpdateDispatchPhone(Number(e.target.value))}
          defaultValue={''}
        >
          <option value="" />
          <OptionList
            optionData={data.branchOptions[branchIndex].dispatchOptions}
            attributeID="dispatchId"
            attributeName="dispatchName"
          />
        </Select>
      </div>,
    );
  }

  function UpdateDispatchPhone(dispatchId: number) {
    if (dispatchId) {
      for (let i = 0; i < data.branchOptions.length; i++) {
        for (let j = 0; j < data.branchOptions[i].dispatchOptions.length; j++) {
          if (
            data.branchOptions[i].dispatchOptions[j].dispatchId == dispatchId
          ) {
            setHtmlPhone(
              data?.branchOptions[i]?.dispatchOptions[j]?.dispatchPhone,
            );
            return;
          }
        }
      }
    }
    setHtmlPhone('');
  }

  function UpdateDriver(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.set(
      'isIndependentContractor',
      (formData.get('isIndependentContractor') === 'on').toString(),
    );
    const appendix = {
      employeeId: employeeId,
    };
    PutForm(`/Employee/UpdateDriver`, formData, appendix).then(() =>
      RefreshPage('Details', ['Audit', 'Summary']),
    );
  }

  const hireDateOnly = data.hireDate?.split('T')[0];

  return (
    <Form onSubmit={e => UpdateDriver(e)}>
      <div className={classes.attribute}>
        <label htmlFor="firstName">
          First Name <span className={classes.required}>*</span>
        </label>
        <Input defaultValue={data.firstName} name="firstName" id="firstName"/>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="lastName">Last Name</label>
        <Input defaultValue={data.lastName} name="lastName" id="lastName"/>
      </div>
      <div className={classes.attribute}>
        <label htmlFor="mobile">
          Mobile <span className={classes.required}>*</span>
        </label>
        <Phone
          defaultValue={data.mobile}
          name="mobile"
          id="mobile"
          onChange={null}
          expectsEventObject={false}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="email">Email</label>
        <Input type="email" defaultValue={data.email} name="email" id="email"/>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="phone">Home Phone</label>
        <Phone
          defaultValue={data.phone}
          name="phone"
          id="phone"
          onChange={null}
          expectsEventObject={false}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="hireDate">Hire Date</label>
        <Input defaultValue={hireDateOnly} name="hireDate" type="date" id="hireDate"/>
      </div>
      <div className={classes.attribute}>
        <label htmlFor="termDate">Term Date</label>
        <Input defaultValue={data.termDate?.split('T')[0]} name="termDate" type="date" id="termDate"/>
      </div>
      <div className={`${classes.attribute}`}>
        <label htmlFor="branchId">Branch</label>
        <Select
          name="branchId"
          id="branchId"
          defaultValue={data.branchId}
          onChange={e => UpdateBranch(Number(e.target.value))}
        >
          <OptionList
            optionData={data.branchOptions}
            attributeID="branchId"
            attributeName="branchName"
          ></OptionList>
        </Select>
      </div>
      {dispatchOptions}
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="dispatchPhone">Dispatch Phone</label>
        <Input
          key={htmlPhone}
          disabled
          value={htmlPhone}
          name="dispatchPhone"
          id="dispatchPhone"
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="preferredTruckId">Preferred Truck</label>
        <Select defaultValue={data?.preferredTruckId} name="preferredTruckId" id="preferredTruckId">
          <option value="">No Preference</option>
          <OptionList
            optionData={data.truckOptions}
            attributeID="truckId"
            attributeName="description"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="preferredTrailerId">Preferred Trailer</label>
        <Select
          defaultValue={data?.preferredTrailerId}
          name="preferredTrailerId"
          id="preferredTrailerId"
        >
          <option value="">No Preference</option>
          <OptionList
            optionData={data.trailerOptions}
            attributeID="trailerId"
            attributeName="description"
          />
        </Select>
      </div>
      <div className={`${classes.attribute}`}>
        <label htmlFor="samsaraDriverId">Samsara ID</label>
        <Input defaultValue={data.samsaraDriverId} name="samsaraDriverId" id="samsaraDriverId"/>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="payrollId">Payroll ID</label>
        <Input defaultValue={data.payrollId} name="payrollId" id="payrollId"/>
      </div>
      <div className={classes.attribute}>
        <label>
          <Input
            defaultChecked={data.isIndependentContractor}
            name="isIndependentContractor"
            type="checkbox"
          />
          Independent Contractor
        </label>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="note">Note</label>
        <TextArea rows={4} defaultValue={data.note} name="note" id="note"/>
      </div>
      <div>
        <Button type="submit" variant="good" disabled={true}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
export default Details;
