import React, { useState } from 'react';
import { IoLocationOutline } from 'react-icons/io5';
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io';

import classes from './Assign.module.css';
import classesGlobal from '../../assets/Global.module.css';
import PostForm from '../../api/internal/PostForm';
import Button from '../../assets/essentials/Button';
import Select from '../../assets/essentials/Select';
import Form from '../../assets/essentials/Form';
import OptionList from '../../helpers/options/OptionList';
import LocationForm from '../creates/location/LocationForm';
import StopInfo from '../views/load/stop/StopInfo';
import Error from '../../helpers/error/Error';
import Create from '../../helpers/error/Create';
import Input from '../../assets/essentials/Input';

function Stop({
  stopTypeOptions,
  timeZoneOptions,
  loadId,
  RefreshPage,
  GetLoad,
}) {
  const [isCreateStopOpen, setIsCreateStopOpen] = useState(false);
  const [locationInfo, setLocationInfo] = useState<LocationInfo>();
  const [stop] = useState<Stop>();
  const [isAppointmentRange, setIsAppointmentRange] = useState(
    stop ? stop.appointmentTimeUpperRange != null : false,
  );

  function CreateStop(e) {
    e.preventDefault();
    if (
      !locationInfo?.locationName &&
      !locationInfo?.addressLineOne &&
      !locationInfo?.city &&
      !locationInfo?.zip &&
      !locationInfo?.state &&
      !locationInfo?.country
    ) {
      return Create(<Error customMessage={'Location not valid'} />);
    }
    const formData = new FormData(e.target);
    const appendix = {
      loadId: loadId,
    };
    Object.keys(locationInfo).forEach(param => {
      const value = locationInfo[param];
      if (value != null) {
        formData.append(param, value);
      }
    });
    PostForm(`/Load/CreateStop`, formData, appendix)
      .then(() => {
        RefreshPage('Itinerary', ['Audit', 'Summary']);
        GetLoad();
      })
      .catch(error => {
        // not forcing user to start over if response fails
        if (error.response) {
          setIsCreateStopOpen(true);
        } else {
          setIsCreateStopOpen(false);
        }
      });
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button
          type="button"
          onClick={() => {
            setIsCreateStopOpen(!isCreateStopOpen);
          }}
        >
          <IoLocationOutline />
          <span>Create a Stop</span>
        </button>
      </div>
      <div className={`${classes.expandable} ${classes.stop}`}>
        {isCreateStopOpen && (
          <Form
            onSubmit={e => CreateStop(e)}
            className={classesGlobal.viewGrid}
            autoComplete="off"
          >
            <div
              className={`${classesGlobal.attribute}  ${classesGlobal.span3}`}
            >
              <label>
                Stop Type <span className={classesGlobal.required}>*</span>
              </label>
              <Select required defaultValue="" name="stopTypeId">
                <option disabled value="">
                  -- Required --
                </option>
                <OptionList
                  optionData={stopTypeOptions}
                  attributeID="stopTypeId"
                  attributeName="type"
                />
              </Select>
            </div>
            <div className={`${classesGlobal.viewGrid} ${classesGlobal.span3}`}>
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
              >
                <h4>Appointment Information</h4>
                <label>
                  Date <span className={classesGlobal.required}>*</span>
                </label>
                <Input
                  required
                  type="date"
                  defaultValue={stop?.appointmentDate}
                  name="appointmentDate"
                />
              </div>

              <div className={`${classesGlobal.attribute}`}>
                <label>
                  Time
                  {!isAppointmentRange ? (
                    <IoIosAddCircleOutline
                      onClick={() => setIsAppointmentRange(true)}
                      title="Add Appointment Window"
                    />
                  ) : (
                    <IoMdRemoveCircleOutline
                      onClick={() => setIsAppointmentRange(false)}
                      title="Remove Appointment Window"
                    />
                  )}
                </label>
                <Input
                  type="time"
                  defaultValue={stop?.appointmentTime}
                  name="appointmentTime"
                  required={isAppointmentRange}
                />
              </div>
              {isAppointmentRange && (
                <div className={classesGlobal.attribute}>
                  <label>Time Upper</label>
                  <Input
                    type="time"
                    defaultValue={stop?.appointmentTimeUpperRange}
                    name="appointmentTimeUpperRange"
                    required={isAppointmentRange}
                  />
                </div>
              )}
              <div className={classesGlobal.attribute}>
                <label>Time Zone</label>
                <Select value={stop?.timeZone} name="ianaTimeZone">
                  <OptionList
                    optionData={timeZoneOptions}
                    attributeID="iana"
                    attributeName="abbreviation"
                  />
                </Select>
              </div>
            </div>
            <LocationForm
              locationInfo={locationInfo}
              setLocationInfo={setLocationInfo}
            />
            <StopInfo stop={stop} />
            <div className={classesGlobal.pairedButtons}>
              <div>
                <Button type="submit" variant="good" disabled={true}>
                  Submit
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setLocationInfo(null);
                    setIsCreateStopOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Stop;
