import React, { useEffect } from 'react';

import classes from '../../../assets/Global.module.css';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';

function Details({
  loadDetails,
  createLoadOptionLists,
  minCargoAreaLengths,
  setMinCargoAreaLengths,
  UpdateParam,
  UpdateStopListLength,
  requirementDisplay,
  HandleCustomerSelection,
  REEFER_EQUIPMENT_TYPE_IDS,
  POWER_ONLY_IDS,
}: {
  loadDetails: CreateLoadParam;
  createLoadOptionLists: CreateLoadOptions;
  minCargoAreaLengths: Array<MinCargoAreaLengthOption>;
  setMinCargoAreaLengths: Function;
  UpdateParam: Function;
  UpdateStopListLength: Function;
  requirementDisplay: LoadRequirementDisplay;
  HandleCustomerSelection: Function;
  REEFER_EQUIPMENT_TYPE_IDS: {
    reefer: number;
    vanOrReefer: number;
  };
  POWER_ONLY_IDS: Array<number>;
}) {
  useEffect(() => {
    if (
      minCargoAreaLengths.length === 0 &&
      createLoadOptionLists?.equipmentOptions.length > 0
    ) {
      setMinCargoAreaLengths(
        createLoadOptionLists.equipmentOptions[0].minCargoAreaLengthOptions,
      );
    }
  }, [
    createLoadOptionLists?.equipmentOptions,
    minCargoAreaLengths.length,
    setMinCargoAreaLengths,
  ]);

  function UpdateEquipment(equipmentTypeId) {
    let equipmentTypeIndex = createLoadOptionLists.equipmentOptions.findIndex(
      x => x.equipmentTypeId === Number(equipmentTypeId),
    );

    if (!isReeferLoad) {
      UpdateParam(null, 'temperature');
    }

    setMinCargoAreaLengths(
      createLoadOptionLists.equipmentOptions[equipmentTypeIndex]
        .minCargoAreaLengthOptions,
    );

    UpdateParam(equipmentTypeId, 'equipmentTypeId');

    if (POWER_ONLY_IDS.includes(
      Number(equipmentTypeId),
    )) {
      //this was set to 7 in the equipments table in the database, i can't remember why, i'm sorry
      const POWER_ONLY_MIN_CARGO_AREA_LENGTH_ID = '7';
      UpdateParam(POWER_ONLY_MIN_CARGO_AREA_LENGTH_ID, 'minCargoAreaLengthId');
    }
  }

  const isPowerOnlyLoad = POWER_ONLY_IDS.includes(
    Number(loadDetails.equipmentTypeId),
  );


  const isReeferLoad = Object.values(REEFER_EQUIPMENT_TYPE_IDS).includes(
    Number(loadDetails.equipmentTypeId),
  );

  const formattedAvailableCredit = loadDetails.availableCredit
    ? loadDetails.availableCredit.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : '$0.00';
  const customerHasCredit = loadDetails.availableCredit > 0;

  // Temperature input disabled for all eq types except reefer & vanOrReefer
  // Temperature value is only required for temerature-controlled freight in reefer trailer
  // vanOrReefer eq type likely only used for dry freight (no temp requirement)
  const isTempRequired =
    Number(loadDetails.equipmentTypeId) === REEFER_EQUIPMENT_TYPE_IDS.reefer;

  return (
    <>
      <h1>Details</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} `}>
          <label>
            Customer <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={loadDetails?.customerId ?? ''}
            onChange={e => {
              HandleCustomerSelection(e);
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={createLoadOptionLists?.customerOptions}
              attributeID="customerId"
              attributeName="customerName"
            />
          </Select>
        </div>
        <div className={`${classes.attributeLabel} ${classes.span2}`}>
          <label className={!customerHasCredit && `${classes.error}`}>
            {'Available Credit: '}
            <span>{formattedAvailableCredit}</span>
          </label>
        </div>
        <div className={classes.attribute}>
          <label>Reference Number</label>
          <Input
            defaultValue={loadDetails?.reference}
            onChange={e => UpdateParam(e.target.value, 'reference')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute}`}>
          <label>
            Commodity
            {!isPowerOnlyLoad ? (
              <span className={classes.required}>{' *'}</span>
            ) : null}
          </label>
          <Select
            defaultValue={loadDetails?.commodityId ?? ''}
            onChange={e => {
              UpdateParam(e.target.value, 'commodityId');
              requirementDisplay.commodity =
                e.target.selectedOptions[0].textContent;
            }}
          >
            <option disabled value="">
              {isPowerOnlyLoad
                ? '-- Not Required For Power Only --'
                : '-- Required --'}
            </option>
            <OptionList
              optionData={createLoadOptionLists?.commodityOptions}
              attributeID="commodityId"
              attributeName="type"
              attributeGroup="group"
            />
          </Select>
        </div>
        <div className={`${classes.attribute}`}>
          <label htmlFor="branchId">
            Branch <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={createLoadOptionLists?.userBranchId}
            onChange={e => UpdateParam(e.target.value, 'branchId')}
            required
          >
            <OptionList
              optionData={createLoadOptionLists?.branchOptions}
              attributeID="branchId"
              attributeName="branchName"
            />
          </Select>
        </div>
        <div
          className={`${classes.attribute} ${isPowerOnlyLoad && classes.span3}`}
        >
          <label>
            Equipment <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={loadDetails?.equipmentTypeId ?? ''}
            onChange={e => {
              UpdateEquipment(e.target.value);
              requirementDisplay.equipment =
                e.target.selectedOptions[0].textContent;
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={createLoadOptionLists?.equipmentOptions}
              attributeID="equipmentTypeId"
              attributeName="type"
            />
          </Select>
        </div>
        {!isPowerOnlyLoad && (
          <div
            className={`${classes.attribute} ${!isReeferLoad && classes.span2}`}
          >
            <label>
              Length <span className={classes.required}>*</span>
            </label>
            <Select
              defaultValue={loadDetails?.minCargoAreaLengthId ?? ''}
              onChange={e => {
                UpdateParam(e.target.value, 'minCargoAreaLengthId');
                requirementDisplay.length =
                  e.target.selectedOptions[0].textContent;
              }}
            >
              <option disabled value="">
                -- Required --
              </option>
              <OptionList
                optionData={minCargoAreaLengths}
                attributeID="minCargoAreaLengthId"
                attributeName="displayLength"
              />
            </Select>
          </div>
        )}
        {isReeferLoad && (
          <div className={`${classes.attribute}`}>
            <label>
              Temperature (°F){' '}
              {isTempRequired ? (
                <span className={classes.required}>*</span>
              ) : null}
            </label>
            <Input
              defaultValue={loadDetails?.temperature}
              onChange={e => UpdateParam(e.target.value, 'temperature')}
              type="number"
            />
          </div>
        )}
        <div className={classes.attribute}>
          <label>Weight</label>
          <Input
            defaultValue={loadDetails?.weight}
            onChange={e => UpdateParam(e.target.value, 'weight')}
            type="number"
          />
        </div>
        <div className={classes.attribute}>
          <label>Pallets</label>
          <Input
            defaultValue={loadDetails?.pallets}
            onChange={e => UpdateParam(e.target.value, 'pallets')}
            type="number"
          />
        </div>
        <div className={classes.attribute}>
          <label>Cases</label>
          <Input
            defaultValue={loadDetails?.cases}
            onChange={e => UpdateParam(e.target.value, 'cases')}
            type="number"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Instructions</label>
          <TextArea
            rows={3}
            defaultValue={loadDetails?.instructions}
            name="instructions"
            onChange={e => UpdateParam(e.target.value, 'instructions')}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Stops</label>
          <Input
            defaultValue={loadDetails?.stopList.length}
            onChange={e => UpdateStopListLength(e.target.value, 'stops')}
            type="number"
            min="0"
            max="50"
            step="1"
          />
        </div>
      </div>
    </>
  );
}

export default Details;
