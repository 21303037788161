import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import PutForm from '../../../api/internal/PutForm';
import Button from '../../../assets/essentials/Button';
import Form from '../../../assets/essentials/Form';

function Details({
  data,
  assetAssignmentId,
  RefreshPage,
}: {
  data: PayrollDetails;
  assetAssignmentId: Number;
  RefreshPage: Function;
}) {
  function UpdatePayableDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      assetAssignmentId: assetAssignmentId,
    };
    PutForm(`/Accounting/UpdatePayrollDetails`, formData, appendix).then(() => {
      RefreshPage('Details', ['Summary']);
    });
  }

  return (
    <>
      <Form onSubmit={e => UpdatePayableDetails(e)}>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Driver</label>
          <Input disabled type="text" value={data?.driverName} />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label htmlFor='workAndPaperworkCompletedDate'>Work And Paperwork Completed Date</label>
          <Input
            type="date"
            defaultValue={data?.workAndPaperworkCompletedDate}
            name="workAndPaperworkCompletedDate"
            id="workAndPaperworkCompletedDate"
          />
        </div>
        <div className={`${classes.attribute}`}>
          <label htmlFor='loadedPayMileage'>Load Mileage</label>
          <Input
            type="number"
            defaultValue={data?.loadedPayMileage}
            name="loadedPayMileage"
            id="loadedPayMileage"
            step={0.01}
            min={0}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label htmlFor='deadheadPayMileage'>Deadhead Mileage</label>
          <Input
            type="number"
            defaultValue={data?.deadheadPayMileage}
            name="deadheadPayMileage"
            id="deadheadPayMileage"
            step={0.01}
            min={0}
          />
        </div>
        <div>
          <Button type="submit" variant="good" disabled={true}>
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
}
export default Details;
