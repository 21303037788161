import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import View from '../../helpers/slab/View';
import ViewDriver from '../../features/views/users/driver/Driver';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import Get from '../../api/internal/Get';

function DriverBoard({ toggleBoardRefresh }) {
  const [driverBoard, setDriverBoard] = useState<Array<DriverBoard>>([]);

  const filterValue = useOutletContext();

  useEffect(() => {
    GetDriverBoard();
  }, [toggleBoardRefresh]);

  function GetDriverBoard() {
    Get(`/Employee/GetDriverBoard`).then(response => {
      if (response) {
        setDriverBoard(response.data);
      }
    });
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'driverName',
      attributeprimaryhidden: 'employeeId',
      header: 'Driver',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'status',
      header: 'Status',
    },
    {
      key: '3',
      attribute: 'hireDate',
      header: 'Hire Date',
    },
    {
      key: '4',
      attribute: 'driverMobile',
      header: 'Mobile',
    },
    {
      key: '5',
      attribute: 'preferredTruck',
      header: 'Preferred Truck',
    },
    {
      key: '6',
      attribute: 'driverBranch',
      header: 'Branch',
    },
  ];

  let tableData = FilterData(driverBoard, filterValue);

  return <BBITable data={tableData} columns={columnConfig} />;
}

export default DriverBoard;
