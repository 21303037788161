import React, { useEffect, useState } from 'react';

import Slab from '../../../helpers/slab/Slab';
import Details from './Details';
import Summary from './Summary';
import Notes from './Notes';
import Contacts from './Contacts';
import Billing from './Billing';
import Audit from '../generic/Audit';
import Get from '../../../api/internal/Get';
import Services from './Services';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';
import Admin from './Admin';
import Files from '../../creates/file/Files';

function Vendor({
  vendorId,
  vendorName,
  startPage,
}: {
  vendorId: number;
  vendorName: String;
  startPage?: String;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [vendorDataObject] = useState<VendorDataObject>({
    Admin: null,
    Audit: null,
    Billing: null,
    Contacts: null,
    Details: null,
    Notes: null,
    Services: null,
    Summary: null,
    Files: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function HandlePageChange(params) {
    if (!vendorDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          vendorDataObject[params] = response.data;
        }
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    if (vendorDataObject) {
      setCurrentPage(null);
      vendorDataObject[currentPage] = null;
      additionalPages?.forEach(pageName => {
        vendorDataObject[pageName] = null;
      });
      HandlePageChange(currentPage);
    }
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            HandlePageChange={HandlePageChange}
            data={vendorDataObject?.Summary}
          />
        ),
        callerPath: `/Vendor/GetVendorSummary/${vendorId}`,
      },
      Details: {
        content: (
          <Details
            data={vendorDataObject?.Details}
            vendorId={vendorId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Vendor/GetVendorDetails/${vendorId}`,
      },
      Billing: {
        content: (
          <Billing
            data={vendorDataObject?.Billing}
            vendorId={vendorId}
            RefreshPage={RefreshPage}
            HandlePageChange={HandlePageChange}
          />
        ),
        callerPath: `/Vendor/GetVendorBilling/${vendorId}`,
      },
      Contacts: {
        content: (
          <Contacts
            data={vendorDataObject?.Contacts}
            vendorId={vendorId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Vendor/GetVendorContacts/${vendorId}`,
      },
      Notes: {
        content: (
          <Notes
            data={vendorDataObject?.Notes}
            vendorId={vendorId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Vendor/GetVendorNotes/${vendorId}`,
      },
      Services: {
        content: (
          <Services
            data={vendorDataObject?.Services}
            vendorId={vendorId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Vendor/GetVendorServices/${vendorId}`,
      },
      Files: {
        content: (
          <Files
            data={vendorDataObject.Files}
            fileCategory={'vendor'}
            categoryId={vendorId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/File/GetAllFiles/vendor-${vendorId}`,
      },
      Audit: {
        content: <Audit data={vendorDataObject?.Audit} />,
        callerPath: `/Vendor/GetVendorAudit/${vendorId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            data={vendorDataObject?.Admin}
            vendorId={vendorId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Vendor/GetVendorAdmin/${vendorId}`,
      };
  }
  return (
    <Slab
      title={
        <>
          {vendorName}
          <p>Vendor</p>
        </>
      }
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={vendorDataObject}
    />
  );
}
export default Vendor;
